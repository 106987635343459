html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

li > a {
  text-decoration: none;
}

@media (min-width: calc(37.5rem + .001px)) {
  .main-bottom-text {
    text-align: end;
  }
}

@media (max-width: 600px) {
  body {
    overflow-x: hidden;
  }

  section {
    flex-direction: column;
  }

  .menu-bar {
    visibility: visible;
    color: #f7e8ca;
    font-size: 1.5rem;
  }

  .menu-bar:active {
    color: #293a31;
  }

  .primary-nav {
    width: 100%;
    z-index: -1;
    height: 100vh;
    background-color: #101714;
    flex-direction: column;
    padding-top: 10rem;
    transition: all 1s ease-in-out;
    position: absolute;
    top: -1500%;
    left: 0;
  }

  .primary-nav > li {
    padding-block: 3rem;
    font-size: 3rem;
  }

  .primary-nav.active {
    top: 0;
  }

  main .main-container > * {
    margin-bottom: 1rem;
  }

  .contact-container {
    text-align: center;
  }
}

@media (min-width: 600.001px) {
  .menu-bar {
    visibility: hidden;
  }
}

@media (max-width: calc(62rem - .001px)) {
  main .main-title-container, .card-container {
    flex-direction: column;
    gap: 1.5rem;
  }

  main .main-button-container {
    max-width: none !important;
    flex-direction: row !important;
  }

  main .main-bottom-container {
    flex-direction: column;
  }

  main .main-bottom-container .main-bottom-text {
    text-align: left;
    max-width: 60ch;
  }
}

@font-face {
  font-family: trap-bold;
  src: url("Trap-Bold.61e9c793.otf");
}

@font-face {
  font-family: trap-medium;
  src: url("Trap-Medium.67cad596.otf");
}

@font-face {
  font-family: trap-regular;
  src: url("Trap-Regular.b52776d3.otf");
}

@font-face {
  font-family: trap-thin;
  src: url("Trap-Light.e79d4a8f.otf");
}

@font-face {
  font-family: trap-extraBold;
  src: url("Trap-Black.63f39e31.otf");
}

.card-container {
  justify-content: center;
  align-items: none;
  gap: 1.5rem;
  display: flex;
}

.card-container .card {
  max-width: 23rem;
  background-color: #293a31;
  border: 1px solid #3d4c45;
  border-radius: .3125rem;
  padding: 2em;
  box-shadow: 10px 10px #00000040;
}

.card-container .card-title {
  font-size: 1.875rem;
}

.card-container .card-text {
  color: #69756f;
}

.project-container {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  display: flex;
}

.project-card {
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  opacity: 0;
  max-width: 30rem;
  border: 1px solid #69756f;
  padding: 2rem 3rem;
  transition: all .5s;
  transform: translateY(50px);
}

.project-card > :not(.card--link) {
  margin-bottom: .5rem;
}

.project-card .card-title {
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  font-family: trap-bold;
  font-size: 1.5625rem;
}

.project-card .card--text {
  opacity: .2;
  font-size: .9rem;
}

.project-card .card--link {
  position: relative;
}

.project-card .card--link a {
  color: #f7e8ca;
  -o-transition: color .3s ease;
  margin-right: 1.5rem;
  transition: color .3s;
}

.project-card .card--link a:hover {
  color: #e5b555;
}

.project-card .card--link i {
  font-size: 1.25rem;
}

.project-card .toolContainer {
  gap: .5rem;
  display: flex;
}

.project-card .toolContainer h3 {
  color: #69756f;
  background-color: #19231d;
  padding: .1rem .4rem;
  font-size: .5rem;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #f7e8ca;
  background-color: #293a31;
  font-family: trap-regular;
  position: relative;
  overflow-x: hidden;
}

.scroll-down header {
  transform: translate3d(0, -100%, 0);
}

.scroll-up header {
  background-color: #0000;
  padding-block: 1rem;
  box-shadow: 1px 1px 20px #ffffff15;
}

header {
  z-index: 9999;
  -o-transition: all .5s ease;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding-block: 1.9942rem;
  transition: all .5s;
  position: sticky;
  top: 0;
}

section {
  padding-block: 5rem;
  padding-inline: min(10%, 12rem);
  line-height: 1.5;
}

li > a {
  color: #f7e8ca;
  font-family: trap-medium;
  position: relative;
}

li > a:hover, li > a:focus {
  color: #e5b555;
}

ul {
  justify-content: none;
  align-items: center;
  gap: 1.5rem;
  font-size: 1.25rem;
  display: flex;
}

ul .active {
  background-color: #101714;
  border-radius: .3125rem;
  padding: 1rem 2rem;
}

nav {
  justify-content: space-between;
  align-items: center;
  gap: none;
  padding-inline: min(10%, 12rem);
  display: flex;
}

.sm-item {
  font-size: 1.5625rem;
}

main {
  justify-content: center;
  align-items: center;
  gap: none;
  user-select: none;
  padding-block: 5rem;
  padding-inline: min(10%, 12rem);
  display: flex;
}

main .main-container {
  width: min(70rem, 100%);
  transform: translateY(100px);
}

main .main-title-container {
  display: flex;
}

main .main-title-container .main-title {
  font-family: trap-extraBold;
  font-size: clamp(2.5rem, 5vw, 5.625rem);
  position: relative;
}

main .main-title-container .main-title span {
  -webkit-text-stroke: 2px #101714;
  z-index: -1;
  color: #0000;
  position: absolute;
  top: 7px;
  left: 7px;
}

main .main-button-container {
  max-width: 404px;
  width: 100%;
  justify-content: none;
  align-items: none;
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

main .main-button-container a {
  width: 100%;
}

main .main-button-container .main-button {
  cursor: pointer;
  width: 100%;
  border: 1px solid #101714;
  border-radius: .3125rem;
  padding-block: .9em;
  font-family: trap-bold;
  font-size: 1.25rem;
  transition: all .3s;
  box-shadow: 5px 5px #00000040;
}

main .main-button-container .work-button {
  color: #f7e8ca;
  background-color: #101714;
}

main .main-button-container .work-button:hover {
  background-color: #e5b555;
}

main .main-button-container .resume-button {
  background-color: #293a31;
}

main .main-button-container .resume-button:hover {
  color: #f7e8ca;
  background-color: #e5b555;
}

main .main-bottom-container {
  color: #69756f;
  justify-content: space-between;
  margin-top: .75rem;
  display: flex;
}

main .main-bottom-container .main-title-pos {
  font-family: trap-bold;
  font-size: 1.25rem;
  line-height: 1.5;
}

main .main-bottom-container .main-bottom-text {
  max-width: 21.875rem;
  font-family: trap-thin;
  font-size: clamp(.7rem, 3vw, .9375rem);
  line-height: 1.5;
}

.title-anim {
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  opacity: 0;
}

.contact-container {
  width: min(70rem, 100%);
}

.contact-container .contact-title {
  max-width: 35rem;
  margin-bottom: 1.5rem;
  font-family: trap-medium;
  font-size: clamp(clamp(.7rem, 3vw, .9375rem), 3vw, 1.5625rem);
}

.contact-container .contact-text {
  max-width: 36rem;
  font-size: clamp(.7rem, 3vw, .9375rem);
}

.contact-container > :not(button) {
  margin-bottom: 1.5rem;
}

.contact-container button {
  color: #f7e8ca;
  cursor: pointer;
  -o-transition: all .5s ease-in-out;
  background-color: #040605;
  border: none;
  padding: 1rem 2rem;
  font-family: trap-medium;
  font-size: clamp(40%, 4vw, 1.5625rem);
  transition: all .5s ease-in-out;
}

.contact-container button:hover, .contact-container button:focus {
  color: #293a31;
  box-shadow: inset 15em 0 #f7e8ca;
}

footer {
  text-align: center;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

footer h1 {
  opacity: .2;
  font-size: clamp(.4rem, 3vw, .8rem);
}

.section-title-container {
  margin-bottom: 3rem;
  font-family: trap-Bold;
}

.section-title-container h1 {
  color: #e5b555;
}

.section-title-container .section-subtitle {
  -webkit-text-stroke: 1px #f7e8ca;
  color: #0000;
  font-size: clamp(1.1rem, 5vw, 1.5625rem);
}

.hamburger {
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 15px;
  transition-property: opacity, filter;
  transition-duration: .15s;
  transition-timing-function: linear;
  display: inline-block;
  overflow: visible;
}

.hamburger:hover, .hamburger.is-active:hover {
  opacity: .7;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:before, .hamburger.is-active .hamburger-inner:after {
  background-color: #f7e8ca;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  margin-top: -2px;
  display: block;
  top: 50%;
}

.hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
  width: 40px;
  height: 4px;
  background-color: #f7e8ca;
  border-radius: 4px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: ease;
  position: absolute;
}

.hamburger-inner:before, .hamburger-inner:after {
  content: "";
  display: block;
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after {
  bottom: -10px;
}

.hamburger--vortex-r .hamburger-inner {
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
}

.hamburger--vortex-r .hamburger-inner:before, .hamburger--vortex-r .hamburger-inner:after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner:before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner:after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transition-timing-function: cubic-bezier(.19, 1, .22, 1);
  transform: rotate(-765deg);
}

.hamburger--vortex-r.is-active .hamburger-inner:before, .hamburger--vortex-r.is-active .hamburger-inner:after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner:before {
  opacity: 0;
  top: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner:after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*# sourceMappingURL=index.d9993343.css.map */
