html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

li > a {
  text-decoration: none;
}

@media (width > 37.5rem) {
  .main-bottom-text {
    text-align: end;
  }
}
@media (max-width: 600px) {
  body {
    overflow-x: hidden;
  }
  section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .menu-bar {
    visibility: visible;
    color: #f7e8ca;
    font-size: 1.5rem;
  }
  .menu-bar:active {
    color: #293a31;
  }
  .primary-nav {
    position: absolute;
    top: -1500%;
    left: 0;
    width: 100%;
    flex-direction: column;
    transition: 1s ease-in-out;
    padding-top: 10rem;
    z-index: -1;
    height: 100vh;
    background-color: #101714;
  }
  .primary-nav > li {
    padding-block: 3rem;
    font-size: 3rem;
  }
  .primary-nav.active {
    top: 0;
  }
  main .main-container > * {
    margin-bottom: 1rem;
  }
  .contact-container {
    text-align: center;
  }
}
@media (width > 600px) {
  .menu-bar {
    visibility: hidden;
  }
}
@media (width < 62rem) {
  main .main-title-container,
.card-container {
    flex-direction: column;
    gap: 1.5rem;
  }
  main .main-button-container {
    flex-direction: row !important;
    max-width: none !important;
  }
  main .main-bottom-container {
    flex-direction: column;
  }
  main .main-bottom-container .main-bottom-text {
    text-align: left;
    max-width: 60ch;
  }
}
@font-face {
  font-family: trap-bold;
  src: url(/src/font/Trap-Bold.otf);
}
@font-face {
  font-family: trap-medium;
  src: url(/src/font/Trap-Medium.otf);
}
@font-face {
  font-family: trap-regular;
  src: url(/src/font/Trap-Regular.otf);
}
@font-face {
  font-family: trap-thin;
  src: url(/src/font/Trap-Light.otf);
}
@font-face {
  font-family: trap-extraBold;
  src: url(/font/Trap-Black.otf);
}
.card-container {
  display: flex;
  justify-content: center;
  align-items: none;
  gap: 1.5rem;
}
.card-container .card {
  background-color: #293a31;
  box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.25);
  max-width: 23rem;
  border: 1px solid hsl(150deg, 11%, 27%);
  padding: 2em;
  border-radius: 0.3125rem;
}
.card-container .card-title {
  font-size: 1.875rem;
}
.card-container .card-text {
  color: #69756f;
}

.project-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.project-card {
  transition: 500ms ease;
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  opacity: 0;
  transform: translateY(50px);
  max-width: 30rem;
  border: 1px solid #69756f;
  padding: 2rem 3rem;
}
.project-card > *:not(.card--link) {
  margin-bottom: 0.5rem;
}
.project-card .card-title {
  font-family: trap-bold;
  -webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  font-size: 1.5625rem;
}
.project-card .card--text {
  font-size: 0.9rem;
  opacity: 20%;
}
.project-card .card--link {
  position: relative;
}
.project-card .card--link a {
  color: #f7e8ca;
  margin-right: 1.5rem;
  -webkit-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.project-card .card--link a:hover {
  color: #e5b555;
}
.project-card .card--link i {
  font-size: 1.25rem;
}
.project-card .toolContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}
.project-card .toolContainer h3 {
  font-size: 0.5rem;
  background-color: #19231d;
  padding: 0.1rem 0.4rem;
  color: #69756f;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #293a31;
  overflow-x: hidden;
  color: #f7e8ca;
  font-family: trap-regular;
  position: relative;
}

.scroll-down header {
  transform: translate3d(0, -100%, 0);
}

.scroll-up header {
  box-shadow: 1px 1px 20px rgba(255, 255, 255, 0.082);
  padding-block: 1rem;
  background-color: transparent;
}

header {
  position: sticky;
  top: 0;
  z-index: 9999;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding-block: 1.9942rem;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

section {
  padding-block: 5rem;
  padding-inline: min(10%, 12rem);
  line-height: 1.5;
}

li > a {
  font-family: trap-medium;
  color: #f7e8ca;
  position: relative;
}
li > a:hover, li > a:focus {
  color: #e5b555;
}

ul {
  display: flex;
  justify-content: none;
  align-items: center;
  gap: 1.5rem;
  font-size: 1.25rem;
}
ul .active {
  padding: 1rem 2rem;
  background-color: #101714;
  border-radius: 0.3125rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: none;
  padding-inline: min(10%, 12rem);
}

.sm-item {
  font-size: 1.5625rem;
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: none;
  padding-block: 5rem;
  padding-inline: min(10%, 12rem);
  user-select: none;
}
main .main-container {
  width: min(70rem, 100%);
  transform: translateY(100px);
}
main .main-title-container {
  display: flex;
}
main .main-title-container .main-title {
  font-size: clamp(2.5rem, 5vw, 5.625rem);
  font-family: trap-extraBold;
  position: relative;
}
main .main-title-container .main-title span {
  position: absolute;
  -webkit-text-stroke: 2px #101714;
  top: 7px;
  left: 7px;
  z-index: -1;
  color: transparent;
}
main .main-button-container {
  max-width: 404px;
  width: 100%;
  display: flex;
  justify-content: none;
  align-items: none;
  gap: 1.5rem;
  flex-direction: column;
}
main .main-button-container a {
  width: 100%;
}
main .main-button-container .main-button {
  padding-block: 0.9em;
  font-size: 1.25rem;
  font-family: trap-bold;
  border: 1px solid #101714;
  border-radius: 0.3125rem;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: 0.3s ease;
  width: 100%;
}
main .main-button-container .work-button {
  background-color: #101714;
  color: #f7e8ca;
}
main .main-button-container .work-button:hover {
  background-color: #e5b555;
}
main .main-button-container .resume-button {
  background-color: #293a31;
}
main .main-button-container .resume-button:hover {
  background-color: #e5b555;
  color: #f7e8ca;
}
main .main-bottom-container {
  display: flex;
  justify-content: space-between;
  color: #69756f;
  margin-top: 0.75rem;
}
main .main-bottom-container .main-title-pos {
  font-size: 1.25rem;
  font-family: trap-bold;
  line-height: 1.5;
}
main .main-bottom-container .main-bottom-text {
  max-width: 21.875rem;
  line-height: 1.5;
  font-family: trap-thin;
  font-size: clamp(0.7rem, 3vw, 0.9375rem);
}

.title-anim {
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  opacity: 0;
}

.contact-container {
  width: min(70rem, 100%);
}
.contact-container .contact-title {
  font-family: trap-medium;
  font-size: clamp(clamp(0.7rem, 3vw, 0.9375rem), 3vw, 1.5625rem);
  max-width: 35rem;
  margin-bottom: 1.5rem;
}
.contact-container .contact-text {
  max-width: 36rem;
  font-size: clamp(0.7rem, 3vw, 0.9375rem);
}
.contact-container > *:not(button) {
  margin-bottom: 1.5rem;
}
.contact-container button {
  color: #f7e8ca;
  background-color: #040605;
  border: none;
  font-family: trap-thin;
  cursor: pointer;
  font-size: clamp(40%, 4vw, 1.5625rem);
  padding: 1rem 2rem;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  font-family: trap-medium;
}
.contact-container button:hover, .contact-container button:focus {
  -webkit-box-shadow: inset 15em 0 0 0 #f7e8ca;
  box-shadow: inset 15em 0 0 0 #f7e8ca;
  color: #293a31;
}

footer {
  text-align: center;
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
footer h1 {
  font-size: clamp(0.4rem, 3vw, 0.8rem);
  opacity: 0.2;
}

.section-title-container {
  font-family: trap-Bold;
  margin-bottom: 3rem;
}
.section-title-container h1 {
  color: #e5b555;
}
.section-title-container .section-subtitle {
  font-size: clamp(1.1rem, 5vw, 1.5625rem);
  -webkit-text-stroke: 1px #f7e8ca;
  color: transparent;
}

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #f7e8ca;
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #f7e8ca;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r .hamburger-inner::before,
.hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}

.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}

.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}

.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger--vortex-r.is-active .hamburger-inner::before,
.hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}

.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}

.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

