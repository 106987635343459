$primaryClr: #101714;
$secondaryClr: #f7e8ca;
$accentClr: #293a31;
$textClr: #040605;
$fadeClr: #69756f;
$secondAccentClr: #e5b555;

$gap: 1.5rem;
$gapSection: 10rem;
$opacity50: 20%;

$paddinginlinesection: min(10%, 12rem);
$paddingblocksection: 5rem;

$sectionContainerWidth: min(70rem, 100%);
