@use "./variables" as *;
@use "./fonts" as *;
@use "./mixins" as *;

.card-container {
  @include flex(center, none, $gap);

  .card {
    background-color: $accentClr;
    box-shadow: 10px 10px 0px rgba(0, 0, 0, 0.25);
    max-width: 23rem;
    border: 1px solid hsla(150, 11%, 27%, 1);
    padding: 2em;
    border-radius: 0.3125rem;
  }

  .card-title {
    font-size: 1.875rem;
  }

  .card-text {
    color: $fadeClr;
  }
}

.project-container {
  @include flex(center, center, $gap);
  flex-wrap: wrap;
}
.project-card {
  transition: 500ms ease;
  -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  opacity: 0;
  transform: translateY(50px);
  max-width: 30rem;
  border: 1px solid $fadeClr;
  padding: 2rem 3rem;

  & > *:not(.card--link) {
    margin-bottom: 0.5rem;
  }

  .card-title {
    font-family: trap-bold;
    -webkit-clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
    font-size: $fontlrg;
  }

  .card--text {
    font-size: 0.9rem;
    opacity: $opacity50;
  }

  .card--link {
    position: relative;

    a {
      color: $secondaryClr;
      margin-right: $gap;
      -webkit-transition: color 0.3s ease;
      -o-transition: color 0.3s ease;
      transition: color 0.3s ease;

      &:hover {
        color: $secondAccentClr;
      }
    }

    i {
      font-size: $fontmdm;
    }
  }

  .toolContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.5rem;

    h3 {
      font-size: 0.5rem;
      background-color: #19231d;
      padding: 0.1rem 0.4rem;
      color: $fadeClr;
    }
  }
}
