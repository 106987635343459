@use "./variables" as *;

@media (width > 37.5rem) {
  .main-bottom-text {
    text-align: end;
  }
}

@media (max-width: 600px) {
  body {
    overflow-x: hidden;
  }
  section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .menu-bar {
    visibility: visible;
    color: $secondaryClr;
    font-size: 1.5rem;

    &:active {
      color: $accentClr;
    }
  }

  .primary-nav {
    position: absolute;
    top: -1500%;
    left: 0;
    width: 100%;
    flex-direction: column;
    transition: 1s ease-in-out;
    padding-top: 10rem;
    z-index: -1;
    height: 100vh;
    background-color: $primaryClr;

    & > li {
      padding-block: 3rem;
      font-size: 3rem;
    }

    &.active {
      top: 0;
    }
  }

  main .main-container > * {
    margin-bottom: 1rem;
  }

  .contact-container {
    text-align: center;
  }
}
@media (width > 600px) {
  .menu-bar {
    visibility: hidden;
  }
}

@media (width <62rem) {
  main .main-title-container,
  .card-container {
    flex-direction: column;
    gap: $gap;
  }
  main .main-button-container {
    flex-direction: row !important;
    max-width: none !important;
  }

  main .main-bottom-container {
    flex-direction: column;

    .main-bottom-text {
      text-align: left;
      max-width: 60ch;
    }
  }
}
