@use "./variables" as *;
@use "./fonts" as *;

@mixin flex($justify, $align-items, $gap) {
  display: flex;
  justify-content: $justify;
  align-items: $align-items;
  gap: $gap;
}

@mixin paddingsection {
  padding-block: $paddingblocksection;
  padding-inline: $paddinginlinesection;
}

@mixin sectionTitle {
  color: $secondAccentClr;
}
@mixin sectionSubtitle {
  font-size: clamp(1.1rem, 5vw, 1.5625rem);
  -webkit-text-stroke: 1px $secondaryClr;
  color: transparent;
}

@mixin sectionContentTitle {
  font-family: trap-medium;
  font-size: clamp($fontsmll, 3vw, $fontlrg);
  max-width: 35rem;
  margin-bottom: $gap;
}

@mixin sectionContentText {
  font-size: $fontsmll;
  opacity: $opacity50;
  margin-bottom: $gap;
}

@mixin sectionBtn {
  color: $secondaryClr;
  background-color: $textClr;
  border: none;
  font-family: trap-thin;
  cursor: pointer;
}
